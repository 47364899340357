<template>
  <v-card flat class="pa-3 mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col md="6" cols="12">
            <v-select
              :items="[
                { id: 1, descripcion: 'BANCO' },
                { id: 2, descripcion: 'CAJA' },
              ]"
              label="Tipo"
              item-text="descripcion"
              item-value="id"
              required
              outlined
              dense
              hide-details="auto"
              v-model="datos.id_tipo_caja_banco"
            ></v-select>
          </v-col>
          <v-col md="6" cols="12">
            <select-moneda
              :disabled="datos.id > 0 ? true : false"
              :rules="reglas.requerido"
              v-model="datos.moneda"
              :dense="true"
              :outlined="true"
            ></select-moneda>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="reglas.requerido"
              v-model="datos.nombre"
              :label="'Nombre'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="datos.descripcion"
              :label="'Descripcion'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="datos.id_fiscal"
              :label="'Id Fiscal'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-switch v-model="datos.ind_descripcion" :label="`Ind descripcion`"></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="datos.ind_referencia" :label="`Ind referencia`"></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch v-model="datos.ind_pago_compras" :label="`Ind pago compras`"></v-switch>
                <v-row>
                  <v-col cols="6" v-if="datos.ind_pago_compras">
                    <v-switch v-model="datos.ind_cuenta_bancaria" :label="`Ind cuenta bancaria`"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="6">
                    <v-switch v-model="datos.ind_pago" :label="`Ind cobro factura`"></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-switch v-model="datos.ind_vuelto" :label="`Ind Vuelto factura`"></v-switch>
                  </v-col>

                  <v-col cols="6" v-if="datos.ind_pago">
                    <v-switch v-model="datos.ind_igtf" :label="`Ind IGTF`"></v-switch>
                  </v-col>
                  <v-col cols="6" v-if="datos.ind_pago">
                    <v-switch v-model="datos.ind_punto_venta" :label="`Ind punto de venta`"></v-switch>
                  </v-col>

                  <v-col cols="6" v-if="datos.ind_pago">
                    <v-switch v-model="datos.ind_propina" :label="`Ind propina`"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="text-right">
            <v-btn small v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn small color="primary" class="me-3" @click="GuardarTipoPago()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch } from '@mdi/js'
import SeguridadService from '@/api/servicios/SeguridadService'
import CropImagen from '@/components/CropImagen.vue'
import store from '@/store'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'
import SelectMoneda from '@/components/SelectMonedaValor.vue'
export default {
  components: { CropImagen, SelectMoneda },
  props: {
    TipoPagoDatos: Object,
  },
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.TipoPagoDatos)))
    const puestos = ref([])
    const form = ref()
    const cargando = ref(false)
    watch(props, () => {
      resetear()
    })

    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.TipoPagoDatos))

      form.value.resetValidation()
    }
    onBeforeMount(() => {})

    const reglas = {
      id: -1,
      requerido: [v => !!v || 'Es requerido'],
      descripcion: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Nombre debe ser valido',
      ],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const limpiar = () => {
      datos.value = {
        id: -1,
        descripcion: '',
        nombre: '',
        moneda: null,
        id_fiscal: '01',
        ind_vuelto: false,
        ind_pago: false,
        ind_igtf: false,
        id_tipo_caja_banco: null,
        ind_punto_venta: false,
        ind_descripcion: false,
        ind_referencia: false,
        ind_propina: false,
        ind_pago_compras: false,
        ind_cuenta_bancaria: false,
      }
      form.value.resetValidation()
    }

    const GuardarTipoPago = async () => {
      try {
        if (validarForm()) {
          cargando.value = true
          const parametros = {
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
          }
          CuentasService.nuevoTipoPago(parametros)
            .then(response => {
              if (response.data.mensaje == 'GUARDADO CON EXITO') {
                console.log(response.data)
                limpiar()
                context.emit('CargarLista')
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                console.log(response.data)
                store.commit('setAlert', {
                  message: 'UPS, ' + response.data.mensaje,
                  type: 'info',
                })
              }
            })
            .catch(err => {
              console.error(err)
              store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'GuardarTipoPago',
          })
            })
            .finally(() => {
              cargando.value = false
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          
          error: error,
            funcion: 'GuardarTipoPago',
        })
        cargando.value = false
      }
    }

    return {
      icons: {
        mdiCloudSearch,
      },
      valid,
      datos,
      reglas,
      GuardarTipoPago,
      form,
      limpiar,
      config,
      resetear,
      cargando,
    }
  },
}
</script>