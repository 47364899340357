<template>
  <div>
    <p class="text-2xl mb-6">TipoPago</p>

    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-text>
              <tipoPago-nuevo @CargarLista="CargarLista" :TipoPagoDatos="tipoPago"></tipoPago-nuevo>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" cols="12">

          <tipoPago-lista ref="TipoPagoListaRef" @GetEditarTipoPago="GetEditarTipoPago"></tipoPago-lista>
        </v-col>
      </v-row>

    </v-container>



  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TipoPagoNuevo from './components/TipoPagoNuevo.vue';
import { ref } from '@vue/composition-api';
import TipoPagoLista from './components/TipoPagoLista.vue';
// demos

export default {
  components: { TipoPagoNuevo, TipoPagoLista },
  setup() {
    const tipoPago = ref({
      id: -1,
      descripcion: "", 
      nombre: "",  
      moneda: null,  
      id_fiscal: "", 
      ind_vuelto: false, 
      ind_pago: false, 
      ind_igtf: false, 
      id_tipo_caja_banco: null, 
      ind_punto_venta: false, 
      ind_descripcion: false, 
      ind_referencia: false, 
      ind_propina: false
    });
    const TipoPagoListaRef = ref(null)
    const GetEditarTipoPago = (data) => {
    
      tipoPago.value = {
                   ...data
                }
    }

    const CargarLista = () => {
      TipoPagoListaRef.value.cargarTipoPagos()
    }
    return {
      tipoPago,
      CargarLista,
      GetEditarTipoPago,
      TipoPagoListaRef
    }
  },
}
</script>
